.container {
  display: flex;
  overflow: hidden;
  height: 100%;
}

.buffContainer {
  position: fixed;
  inset: 0;
  pointer-events: none;
}

.leftSide {
  flex: 1 auto;
  padding: 2rem 3rem;
  width: 35%;
  padding: 5rem;
  background-color: var(--white);
}

.rightSide {
  flex: 1 auto;
  padding: 2rem 3rem;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--lightGray2);

  p {
    margin: 1.5rem;
    line-height: 1.5;
  }
}
