.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: black;
  background-size: cover;
  background-repeat: no-repeat;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    padding: 16px 16px;

    .logo {
      img {
        max-width: 94px;
        width: 40%;
      }
    }

    @media (max-width: 768px) {
      .header {
        padding: 12px 32px;
      }
    }

    @media (min-width: 768px) {
      .share {
        display: none;
      }
    }

    @media (max-width: 480px) {
      .header {
        padding: 12px 32px;
        display: flex;
        justify-content: space-between;
      }

      .logo {
        img {
          max-width: 94px;
          width: 30%;
        }
      }

      .share {
        width: 54%;
      }
    }
  }
}

.banner {
  // background-color: #262626;
  color: white;
  // opacity: 0.9;
  padding: 2em;
  border-radius: 12px;
  background-color: #100118f2;
  max-width: 580px;
  width: 100%;
  margin-top: 130px;
  margin-left: 50px;

  h1 {
    margin-bottom: 16px;
    font-size: 2em;
  }
}

button {
  display: flex;
  align-items: center;
  background: #b2aeff !important;
  border: none;
  padding: 10px 16px !important;
  border-radius: 48px;
  /*optional*/
  font-family: arial, sans-serif;
  font-weight: 600;
  font-size: 1em;
  /*input has OS specific font-family*/
  color: #191c1f;
  cursor: pointer;
}

@media (max-width: 720px) {
  .banner {
    max-width: none;
    width: 100%;
    margin-top: 100px;
    margin-left: 0px;
    font-size: 14px;

    h1 {
      font-size: 24px;
    }
  }
}

.footer {
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: column;
  left: 0;
  bottom: 0;
  color: white;
  text-align: center;
  padding-bottom: 2em;

  p {
    margin-bottom: 1em;
  }

  img {
    width: 16%;
  }

  .nextGame {
    font-family: arial, sans-serif;
    background-color: #262626;
    color: white;
    opacity: 0.8;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;

    .textContainer {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
      }
    }

    @media (max-width: 480px) {
      .textContainer {
        width: 100%;
      }
    }
  }

  .terms {
    margin-top: 1em;
    a {
      font-size: 0.8em;
      color: lightgray;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 480px) {
    img {
      width: 42%;
    }
  }
}

.footerText {
  font-size: 12px;
  margin: 0.1em 2em;
  color: #d1d5da;
  text-align: left;
}

#SportBuff-container {
  overflow: hidden;
}