.container {
  display: flex;
  height: 100vh;
  z-index: 10;

  .logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 30%;
    img {
      max-width: 80%;
      height: auto;
      object-fit: contain; 
    }
  }
}

#SportBuff-container {
  overflow: hidden;
}
