.ContentList {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}

.ContentList-show {
  background-color: #282c34;
}

.ContentList-hide {
  background-color: transparent;
  padding: 10px;
}

.ContentList-header {
  font-size: 14px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
}

ul {
  list-style-type: none;
  padding: 5;
}

.content-item {
  background: #444;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  width: 250px;
}

.ContentList-time {
  color: rgb(21, 214, 95);
}

.answer {
  background: #555;
  margin: 5px;
  padding: 10px;
  border-radius: 4px;
}

.error {
  color: red;
}

button {
  background-color: #007bff !important;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:active {
  background-color: #004494;
}