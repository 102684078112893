.qrCode {
  position: fixed;
  width: 164px;
  height: 194px;
  bottom: 24px;
  right: 24px;
  padding: 8px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 124px;
    height: 144px;
    padding: 4px;
  }
}

.qrCode img {
  width: 66px;
  height: 22px;
}

.qrCode canvas {
  width: 100%;
  height: 100%;
}
