.content {
  display: flex;
  height: 100vh;
}

.videoNotFound {
  text-align: center;
  color: var(--darkGray);
  font-size: 2rem;
  font-weight: bold;
}
