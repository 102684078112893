.YesNo {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align ContentList to the top */
}

.YesNo-header {
  font-size: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  color: rgb(238, 60, 10);
}

.result {
  font-size: 7em;
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(45, 191, 135);
}

.percentage {
  font-weight: normal;
  font-size: 0.5em;
  margin-left: 10px;
}

.error {
  color: red;
  margin-top: 20px;
}

.ContentList-container {
  max-width: 300px;
  overflow-y: auto; /* Make it scrollable */
}
