.container {
  display: flex;
  overflow: hidden;
  height: 100%;
}

.pagebg {
  background-color: #650039;
  height: 100%;
}

.headerLogo {
  width: 64px;
  padding-left: 10px;
  margin-right: 16px;
}

.buffContainer {
  position: fixed;
  inset: 0;
  pointer-events: none;
}
